import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useTheme } from '@mui/material';
import { ContainerColumn, ContainerRow } from '@src/components/styled';
import { ReactComponent as IncomeIcon } from '@icons/custom/income-light.svg';
import { ReactComponent as OutcomeIcon } from '@icons/custom/expences-light.svg';
import { ReactComponent as QuestionIcon } from '@icons/wolfkit-light/question-circle-light.svg';
import IconText from '@shared/ui/icons/IconText';
import Icon from '@shared/ui/icons/Icon';
import Tooltip from '@shared/ui/tooltips/Tooltip';
import { BodyMedium } from '@src/components/styled/Typography';
import { formatUSD } from '@shared/lib/numbers';
import { WithSkeleton } from '@shared/ui/progress-loaders/Skeleton';
const IncomeDetailsContainer = styled(ContainerColumn)(() => ({
    height: 'auto',
    backgroundColor: '#F8F9FA',
    borderRadius: 6,
}));
const Divider = styled.span(() => ({
    width: '100%',
    maxWidth: 220,
    borderBottom: '1px solid #EFF1F3',
    alignSelf: 'center',
}));
const IncomeDetailsRow = styled(ContainerRow)(props => ({
    width: 'auto',
    height: 39,
    justifyContent: 'space-between',
    padding: `0px ${props.theme.spacing_sizes.m}px`,
}));
const IncomeDetailsRowInner = styled(ContainerRow)(props => ({
    width: 'auto',
    alignItems: 'center',
    gap: props.theme.spacing_sizes.xs * 1.25,
}));
const IncomeDetailsValue = styled(BodyMedium)(() => ({
    fontSize: 18,
    lineHeight: '30px',
    letterSpacing: '-0.306px',
}));
const IncomeDetails = ({ totalIncome, totalOut, isLoading = false, }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    return (_jsx(WithSkeleton, { isLoading: isLoading, width: 312, height: 79, children: _jsxs(IncomeDetailsContainer, { children: [_jsxs(IncomeDetailsRow, { children: [_jsxs(IncomeDetailsRowInner, { children: [_jsx(IconText, { IconComponent: _jsx(Icon, { IconComponent: IncomeIcon, size: 24, color: theme.palette.success.main }), text: t('balance.trader_balance.management_income.total_income'), textColor: theme.palette.text.primary, textFontWeight: 400, containerGap: theme.spacing_sizes.xs * 1.25 }), _jsx(Tooltip, { title: t('placeholders.not_implemented', { ns: 'common' }), placement: 'top-start', arrow: true, children: _jsx(Icon, { size: 16, color: theme.customColors.input.borderHover, IconComponent: QuestionIcon }) })] }), _jsx(IncomeDetailsRowInner, { children: _jsx(IncomeDetailsValue, { children: formatUSD(totalIncome) }) })] }), _jsx(Divider, {}), _jsxs(IncomeDetailsRow, { children: [_jsxs(IncomeDetailsRowInner, { children: [_jsx(IconText, { IconComponent: _jsx(Icon, { IconComponent: OutcomeIcon, size: 24, color: theme.palette.error.main }), text: t('balance.trader_balance.management_income.total_out'), textColor: theme.palette.text.primary, containerGap: theme.spacing_sizes.xs * 1.25, textFontWeight: 400 }), _jsx(Tooltip, { title: t('placeholders.not_implemented', { ns: 'common' }), placement: 'top-start', arrow: true, children: _jsx(Icon, { size: 16, color: theme.customColors.input.borderHover, IconComponent: QuestionIcon }) })] }), _jsx(IncomeDetailsRowInner, { children: _jsx(IncomeDetailsValue, { children: formatUSD(totalOut) }) })] })] }) }));
};
export default IncomeDetails;
